import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const ContactDetailsSection = styled.section`
    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 5px 50px;
    }

`;
export const ContactDetailsLayout = styled.div`

`;
export const ContactLayout = styled.div`
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    min-height: 250px;
    padding: 30px;
    box-shadow: 0px 0px 8px 1px #ddd;
    -webkit-transition: all .5s;
    transition: all .5s;
    margin: 0px 0px 30px;
    text-align: center;

    @media ${device.laptop} {
        margin-bottom:30px;
        min-height: 220px;
    }

    @media ${device.tablet} {
        margin-bottom:30px;
        min-height: 200px;
    }
`;
export const ContactIcon = styled.img`
    width:50px;
    flex-shrink:0;
    margin-bottom:20px;
`;
export const ContactTextLayout = styled.div`


    h4{
        text-align:center;
    }
`;
export const ContactHeading = styled.h4`
    font-size:26px;
    text-align:left;
    margin-bottom:10px;
`;
export const ContactText = styled.p`
    margin-bottom:0px;
`;
