import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Banner from "../components/CommonBanner"
import ContactDetails from "../containers/ContactPage1/ContactDetails";
import ContactForm from "../containers/ContactPage1/ContactForm";
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Layout from "../components/layout";
import BackgroundImg from '../assets/contact-1-images/banner.jpg';

const ContactPage1 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS/>
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="Contact"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Contact Us"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/contact-1"
        />
        <ContactDetails />
        <ContactForm />
        <Footer />
    </Layout>
)

export default ContactPage1;