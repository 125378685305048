import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const ContactFormSection = styled.section`
    background:#fff;
    padding:0px 0px 100px 0px;

    @media ${device.tablet} {
        padding:0px 5px 80px;
    }
`;
export const ContactFormWrapper = styled.div`
    display:flex;
    align-items:center;

    @media ${device.minlaptopL} {
        margin:auto;
        padding-right:15px;
    }

    @media ${device.laptop} {
        flex-direction:column;
    }

`;
export const MapLayout = styled.div`
    flex-shrink:0;

    @media ${device.laptop} {
    width:100%;
    }

`;

export const MapHeading = styled.h3`

`;

export const MapText = styled.p`

`;

export const ImageHolder = styled.div`
    .mapimg{
        height:245px !important;
        width:100%;
    }
`;

export const FormLayout = styled.div`
    background: #f5f5f5;
    padding: 40px 30px;

    @media ${device.laptop} {
        width:100%;
        margin:auto;
        padding:40px 25px;
    }

    @media ${device.tablet} {
        width:100%;
        margin:auto;
        padding:40px 25px;
        margin-bottom:40px;
    }
`;
export const FormHeading = styled.h3`

`;
export const ContactForm = styled.div`

`;

export const  Form = styled.form`
    margin-bottom:0px;
`;
export const  FormGroup = styled.div`
    margin-bottom:20px;
`;
export const  InputText = styled.input`
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 8px 10px;
    width: 100%;
`;
export const MessageTextarea = styled.textarea`
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 8px 10px;
    width: 100%;
    min-height:100px;
`;
export const SubmitBtn = styled.button`
    background: #ff3c1c;
    border: 2px solid #ff3c1c;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;

    :hover{
        text-decoration:none;
        color: #ff3c1c !important;
        background:none !important;
    }
`;

export const TextCenter = styled.div`
    text-align:left;
`;

export const ContactFormSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const ContactFormSpanErr = styled.span`
    color:red;
    font-size:14px;
`;
